import type {
  DecisionAreas,
  MilestonesTrackingData,
  TrackingDataSet,
  TrackingEvent,
} from '../interfaces/analytics.interface';
import {
  DASHBOARD,
  DASHBOARD_INTERACTION,
  DASHBOARD_MODULE_IMPRESSION,
  DASHBOARD_PRODUCT,
  NAVIGATE_ORGANIZER,
  ONBOARDING,
  ONBOARDING_IMPRESSION,
  ONBOARDING_INTERACTION,
  ONBOARDING_PRODUCT,
  ORGANIZER,
  ORGANIZER_ITEM_INTERACTION,
  ORGANIZER_MODULE_IMPRESSION,
  ORGANIZER_PRODUCT,
  ORGANIZER_SOURCE,
  TRACK_INTERACTION,
  TRACK_TYPEAHEAD_INTERACTION,
  WEDDING_VISION,
  WEDDING_VISION_IMPRESSION,
  WEDDING_VISION_INTERACTION,
  WEDDING_VISION_PRODUCT,
} from '../constants/analytics.constants';
import type { AnalyticsPageType } from '../hooks/useAnalytics.hook';
import { useRouter } from 'next/router';
import { NEW_CHECKLIST_VARIANT } from '../../organizer/constants/organizer.constants';
import type { VendorSource } from '../../core/components/BookingComponents/VendorTypeahead/typeahead.interface';

export const pageMap = {
  [DASHBOARD]: {
    impression: DASHBOARD_MODULE_IMPRESSION,
    product: DASHBOARD_PRODUCT,
    interaction: DASHBOARD_INTERACTION,
    navigate: null,
  },
  [ORGANIZER]: {
    impression: ORGANIZER_MODULE_IMPRESSION,
    product: ORGANIZER_PRODUCT,
    interaction: ORGANIZER_ITEM_INTERACTION,
    navigate: NAVIGATE_ORGANIZER,
  },
  [ONBOARDING]: {
    impression: ONBOARDING_IMPRESSION,
    interaction: ONBOARDING_INTERACTION,
    product: ONBOARDING_PRODUCT,
    navigate: null,
  },
  [WEDDING_VISION]: {
    impression: WEDDING_VISION_IMPRESSION,
    interaction: WEDDING_VISION_INTERACTION,
    product: WEDDING_VISION_PRODUCT,
    navigate: null,
  },
};

/** add new analytics tracker keys here */
const dataModelKeys = [
  'trackerIsCustom',
  'trackerValueCenter',
  'trackerCategory',
  'trackerHeader',
  'trackerUserDecisionArea',
  'trackerSelection',
  'trackerPlatform',
  'trackerOrganizerSection',
  'trackerSource',
  'trackerFilter',
  'trackerVariant',
  'trackerFirstNameEntered', // Needed for Optimized Onboarding control & variant
  'trackerLastNameEntered', // Needed for Optimized Onboarding control & variant
  'trackerPartnerFirstNameEntered', // Needed for Optimized Onboarding control
  'trackerPartnerLastNameEntered',// Needed for Optimized Onboarding control
  'trackerStepName', // Needed for Optimized Onboarding control & variant
  'trackerStepNumber', // Needed for Optimized Onboarding control & variant
  'trackerEngagementDate', // Needed for Optimized Onboarding control & variant
  'trackerEngagementDateSource', // Needed for Optimized Onboarding control & variant
  'trackerWeddingLocationSource', // Needed for Optimized Onboarding control & variant
  'trackerFields',
  'trackerAnswer',
  'trackerSelectionType',
  'trackerJobName',
  'trackerJobState',
  'trackerJobPosition',
  'trackerMarketplaceResultsCount',
  'trackerSourceContent',
  'trackerSourcePage',
  'trackerActionDescription',
  'trackerVendorRecSource', // data-tracker-vendor-rec-source
  'trackerChangedFields',
  'trackerSlideSource',
  'trackerName', // So we can override the event name for account settings interactions

  // Entries below were added for Optimized onboarding variant
  'trackerFianceFirstNameEntered', // introduction step
  'trackerFianceLastNameEntered', // introduction step
  'trackerEngagementDateEntered', // engagement date step
  'trackerWeddingDateEntered', // wedding date step
  'trackerWeddingDateSource', // wedding date step
  'trackerWeddingDateFormatEntered', // wedding date step
  'trackerWeddingMonthAndYearEntered', // wedding date step
  'trackerWeddingDate', // wedding date step
  'trackerBookingEntered', // location step
  'trackerBookingSource', // location step
  'trackerIsBookedEntered', // location step
  'trackerMarketEntered', // location step
  'trackerMarketSource', // location step
  'trackerGuestRangeEntered', // guest count step
  'trackerBudgetRangeEntered', // budget step

  // UpNext on homepage aka hero section
  'trackerTaskPosition',
  'trackerTaskTitle',
  'trackerTaskAction',
];

// Any of the keys in dataModelKeys above that need have their values parsed,
// i.e. any values that were added to the element using JSON.stringify()
const dataModelKeysToParse = [
    'trackerFields',
    'trackerVendorRecSource',
    'trackerMarketEntered',
    'trackerBookingEntered',
    'trackerGuestRangeEntered',
    'trackerBudgetRangeEntered',
    'trackerIsBookedEntered',
    'trackerWeddingDateFormatEntered',
    'trackerWeddingMonthAndYearEntered',
];

export function isMobile() {
  if (typeof window !== 'undefined') {
    const devicesRegex = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (devicesRegex.test(navigator.userAgent)) {
      return true;
    }
  }
  return false;
}

function convertBooleanString(str: string) {
  if (str === 'true' || str === 'false') {
    return str === 'true';
  }
  return str;
}

export const sanitizeDataset = (data: TrackingDataSet & { [key: string]: any }) => {
  const sanitized: any = {};
  dataModelKeys.forEach((key) => {
    if (data.hasOwnProperty(key) && data[key]) {
      const trackerLength = 'tracker'.length;
      const newKey = key.slice(trackerLength);

      if (newKey && newKey[0]) {
        const lowerCaseKey = newKey[0].toLowerCase() + newKey.slice(1);
        sanitized[lowerCaseKey] = convertBooleanString(data[key]);
        if (dataModelKeysToParse.includes(key)) {
          try {
            const data = JSON.parse(sanitized[lowerCaseKey]);
            sanitized[lowerCaseKey] = data;
          } catch (error) {
            console.error(`${key} value is not an object.`);
          }
        }
      }
    }
  });

  return sanitized;
};

export function trackEvent(name: string, params: object) {
  if (typeof window !== 'undefined') {
    window.analytics.track(name, params);
  }
}

/** this curry function is only used in Milestones checklist components */
export const curryClickTrackingData = (data: {
  isCustomItem?: any;
  valueCenter?: string | null;
  category?: string | null;
  header?: string | null;
  decisionArea?: DecisionAreas;
  section?: string;
  filter?: string;
}) => {
  const customItemData =
    typeof data.isCustomItem !== 'undefined' ? data.isCustomItem + '' : undefined;
  const router = useRouter();
  const source = router.asPath.includes(DASHBOARD) ? DASHBOARD : ORGANIZER_SOURCE;
  const captureSelection = (selection: string): MilestonesTrackingData => ({
    ['data-tracker-selection']: selection,
    ['data-tracker-is-custom']: customItemData || null,
    ['data-tracker-value-center']: data.valueCenter || null,
    ['data-tracker-category']: data.category || null,
    ['data-tracker-header']: data.header || null,
    ['data-tracker']: TRACK_INTERACTION,
    ['data-tracker-module']: 'milestones',
    ['data-tracker-user-decision-area']: data.decisionArea || null,
    ['data-tracker-organizer-section']: data.section || null,
    ['data-tracker-source']: source,
    ['data-tracker-filter']: data.filter || null,
    ['data-tracker-variant']: NEW_CHECKLIST_VARIANT,
  });
  return captureSelection;
};

/** this tracking function is only used to capture typeahead data in milestones */
export const curryBookingTrackingData = (data: {
  category?: string | null;
  header?: string | null;
  section?: string;
  filter?: string;
}) => {
  const captureSelectionAndSource = (
    selection: string,
    source?: VendorSource
  ): MilestonesTrackingData => ({
    ['data-tracker-selection']: selection,
    ['data-tracker-is-custom']: 'false',
    ['data-tracker-value-center']: 'Marketplace',
    ['data-tracker-category']: data.category || null,
    ['data-tracker-header']: data.header || null,
    ['data-tracker']: TRACK_TYPEAHEAD_INTERACTION,
    ['data-tracker-module']: 'milestones',
    ['data-tracker-user-decision-area']: 'item view',
    ['data-tracker-organizer-section']: data.section || null,
    ['data-tracker-source']: source || null,
    ['data-tracker-filter']: data.filter || null,
    ['data-tracker-variant']: NEW_CHECKLIST_VARIANT,
  });
  return captureSelectionAndSource;
};

export function fireOnce(moduleName: string, trackingCallback: () => void) {
  const hasBeenTracked = sessionStorage.getItem(moduleName) === 'true';

  if (hasBeenTracked) return;

  try {
    trackingCallback();
  } catch (error) {
    console.warn('Tracking error.');
  } finally {
    sessionStorage.setItem(moduleName, 'true');
  }
}

export const trackingDataBase = (page: AnalyticsPageType) => ({
  product: pageMap[page].product,
});

const createTrackingEvents = (page: AnalyticsPageType): TrackingEvent[] => {
  const events = [
    {
      trackingEventName: TRACK_INTERACTION,
      data: {
        ...trackingDataBase(page),
        name: pageMap[page].interaction,
      },
    },
  ];
  return events;
};
export default createTrackingEvents;
